<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
      >
        <v-card>
          <v-sheet
            tile
            height="64"
            color="grey lighten-3"
            class="d-flex"
          >
            <v-row
              class="ma-1"
            >
              <v-col
                cols="3"
              >
                <v-btn
                  outlined
                  color="grey darken-2"
                  @click="value = ''"
                >
                  Aujourd'hui
                </v-btn>
                <v-btn
                  icon
                  @click="$refs.calendar.prev()"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click="$refs.calendar.next()"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
              <v-col
                cols="5"
                class="d-flex align-center"
              >
                <v-toolbar-title
                  v-if="$refs.calendar"
                >
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
              </v-col>
              <v-col
                cols="2"
              >
                <v-select
                  v-model="type"
                  :items="types"
                  dense
                  outlined
                  hide-details
                  label="Affichage"
                ></v-select>
              </v-col>
              <v-col
                cols="2"
              >
                <v-select
                  v-if="type === 'month' || type === 'week'"
                  v-model="weekday"
                  :items="weekdays"
                  dense
                  outlined
                  hide-details
                  label="Jours semaine"
                ></v-select>
              </v-col>
            </v-row>
          </v-sheet>
          <v-sheet height="600">
            <v-calendar
              id="calendar"
              ref="calendar"
              v-model="value"
              :weekdays="weekday"
              :type="type"
              :events="events"
              :event-overlap-mode="mode"
              :event-overlap-threshold="30"
              :event-color="getEventColor"
              locale="fr-FR"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="updateEvents()"
            >
              <template v-slot:day-body="{ date, week, timeToY }">
                <div
                  class="v-current-time"
                  :class="{ first: date === week[0].date }"
                  :style="{ top: nowY(timeToY) }"
                ></div>
              </template>
            </v-calendar>
            <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
              >
                <v-card
                  class="card-creneau"
                  color="white lighten-4"
                  min-width="350px"
                  flat
                >
                  <v-toolbar
                    :color="selectedEvent.color"
                    dark
                  >
                    <v-toolbar-title>
                      <p class="ma-0">
                        {{ selectedEvent.name }}
                      </p>
                      <p class="ma-0 text-caption">
                        {{ selectedEvent.start.toLocaleDateString('fr-FR', { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' }) }}
                      </p>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                      icon
                      @click="$router.push({ name: 'ModifierFormation', params: {id: selectedEvent.formationId } });"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon>
                      <v-icon
                        @click="selectedOpen = false"
                      >
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <p>
                      <v-icon>mdi-account-tie</v-icon>
                      <span v-if="selectedEvent.formateur.id !== -1">
                        {{ selectedEvent.formateur.prenom }} {{ selectedEvent.formateur.nom }}
                      </span>
                      <span v-if="selectedEvent.formateur.id === -1">
                        <i>Formateur non défini</i>
                      </span>
                    </p>
                    <p>
                      <v-icon>mdi-map-marker</v-icon>
                      <span v-if="!selectedEvent.adresse && !selectedEvent.distanciel">
                        <i>Lieu non défini</i>
                      </span>
                      <span v-if="!selectedEvent.adresse && selectedEvent.distanciel">
                        Classe virtuelle
                      </span>
                      <span v-if="selectedEvent.adresse">
                        {{ selectedEvent.adresse.ville }}
                      </span>
                    </p>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      text
                      color="secondary"
                      @click="$router.push({ name: 'FormationDetail', params: {id: selectedEvent.formationId } });"
                    >
                      Afficher les détails
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="2"
      >
        <p>États :</p>
        <v-checkbox
          v-model="etatsSelected"
          label="En cours"
          value="ENCOURS"
          hide-details
          @change="updateURL();updateEvents();"
        ></v-checkbox>
        <v-checkbox
          v-model="etatsSelected"
          label="Ouvertes"
          value="OUVERT"
          hide-details
          @change="updateURL();updateEvents();"
        ></v-checkbox>
        <v-checkbox
          v-model="etatsSelected"
          label="Confirmées"
          value="CONFIRME"
          hide-details
          @change="updateURL();updateEvents();"
        ></v-checkbox>
        <v-checkbox
          v-model="etatsSelected"
          label="Terminées"
          value="TERMINE"
          hide-details
          @change="updateURL();updateEvents();"
        ></v-checkbox>
        <v-checkbox
          v-model="etatsSelected"
          label="Fermées"
          value="FERME"
          hide-details
          @change="updateURL();updateEvents();"
        ></v-checkbox>
        <v-checkbox
          v-model="etatsSelected"
          label="Annulées"
          value="ANNULE"
          hide-details
          @change="updateURL();updateEvents();"
        ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <p>Formateurs :</p>
        <v-select
          outlined
          v-model="speakersSelected"
          :items="speakers"
          item-value="id"
          :menu-props="{ maxHeight: '400' }"
          multiple
          hide-details
          @change="updateURL();updateEvents();"
        >
          <template v-slot:prepend-item>
            <v-list-item
              ripple
              @mousedown.prevent
              @click="toggle()"
            >
              <v-list-item-action>
                <v-icon :color="speakersSelected.length > 0 ? 'grey darken-4' : ''">
                  {{ icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Tous
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:item="{item, attrs, on}">
            <v-list-item
              v-on="on"
              v-bind="attrs"
              #default="{ active }"
            >
              <v-list-item-action>
                <v-checkbox
                  :ripple="false"
                  :input-value="active"
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <v-row
                    v-if="item.id !== -1"
                    no-gutters
                    align="center"
                  >
                    {{ item.prenom }} {{ item.nom }}
                  </v-row>
                  <v-row
                    v-if="item.id === -1"
                    no-gutters
                    align="center"
                  >
                    <i>Faire apparaître les créneaux sans formateur</i>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template
            v-slot:selection="{ item, index }"
          >
            <v-chip
              v-if="index < 3 && item.id !== -1"
            >
              {{ item.prenom }} {{ item.nom }}
            </v-chip>
            <v-chip
              v-if="index < 3 && item.id === -1"
            >
              <i>Aucun formateur</i>
            </v-chip>
            <span
              v-if="index == 3"
              class="grey--text text-caption"
            >
              (+{{ speakersSelected.length - 3 }} formateur{{ (speakersSelected.length - 3) > 1 ? 's' : '' }})
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        md="7"
      >
        <v-text-field
          label="URL d'abonnement iCal"
          outlined
          readonly
          :disabled="speakersSelected.length === 0 || etatsSelected.length === 0"
          id="url-planning"
          hide-details="auto"
          :value="(speakersSelected.length !== 0 && etatsSelected.length !== 0) ? urlPlanning : 'Sélectionnez au moins un état et/ou un formateur'"
          :append-icon="tooltipIcon"
          @click="copyToClipboard"
          @blur="tooltipIcon = 'mdi-content-copy'"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {
    data: () => ({
      type: 'month',
      types: [
        { text: 'Mois', value: 'month' },
        { text: 'Semaine', value: 'week' },
        { text: 'Jour', value: 'day' },
      ],
      selectedEvent: {
        start: new Date(),
        formateur: {},
        adresse: {
          ville: '',
        },
        distanciel: false,
      },
      selectedElement: null,
      selectedOpen: false,
      mode: 'stack',
      weekday: [1, 2, 3, 4, 5, 6, 0],
      weekdays: [
        { text: 'Lun - Dim', value: [1, 2, 3, 4, 5, 6, 0] },
        { text: 'Lun - Ven', value: [1, 2, 3, 4, 5] },
      ],
      value: '',
      creneaux: [],
      speakers: [],
      events: [],
      joursFeries: [],
      colors: [
        'aquamarine', 'burlywood', 'cadetblue', 'coral', 'darkcyan', 'darkolivegreen', 'darksalmon', 'orangered', 'plum', 'sienna',
        'darkturquoise', 'dodgerblue', 'goldenrod', 'hotpink', 'lightgreen', 'lightslategrey', 'midnightblue', 'olive',
      ],
      etats: ['ENCOURS', 'OUVERT', 'CONFIRME', 'TERMINE', 'FERME', 'ANNULE'],
      etatsSelected: ['ENCOURS', 'OUVERT', 'CONFIRME', 'TERMINE', 'FERME', 'ANNULE'],
      speakersSelected: [],
      urlPlanning: '',
      tooltipIcon: 'mdi-content-copy',
      ready: false,
    }),

    created() {
      // Jours fériés de l'année en cours et de la suivante
      this.axios('/planning/joursFeries/' + new Date().getFullYear())
        .then((res) => {
          for (const [date, intitule] of Object.entries(res.data)) {
            this.joursFeries.push({
              name: intitule,
              start: date,
              end: date,
              color: '#3f50b5',
              timed: false,
            });
          }
          return this.axios('/planning/joursFeries/' + (new Date().getFullYear() + 1))
        })
        .then((res) => {
          for (const [date, intitule] of Object.entries(res.data)) {
            this.joursFeries.push({
              name: intitule,
              start: date,
              end: date,
              color: '#3f50b5',
              timed: false,
            });
          }
          return this.axios.get('/intervenants');
        })
        .then((res) => {
          this.speakers = [...res.data];
          this.speakers.sort((a, b) => a.nom.localeCompare(b.nom));
          this.speakers.push({ id: -1 })
          this.speakersSelected = this.speakers.map(s => s.id);
          return res;
        })
        .then((res) => {
          // Chargement au démarrage
          this.updateEvents();
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors du chargement de l\'agenda', type: 'warn' });
        });

      this.axios.get('/planning/getURL')
        .then((res) => {
          this.urlPlanning = res.data;
        });
    },

    mounted() {
      // Permet d'éviter que le @change du v-calendar ne se déclenche au chargement, problème de synchro
      this.ready = true;
      this.scrollToTime();
      this.updateTime();
    },

    computed: {
      allSpeakers () {
        return this.speakersSelected.length === this.speakers.length;
      },

      someSpeakers () {
        return this.speakersSelected.length > 0 && !this.allSpeakers;
      },

      icon () {
        if (this.allSpeakers) return 'mdi-close-box'
        if (this.someSpeakers) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },

      cal () {
        return this.ready ? this.$refs.calendar : null;
      },
    },

    watch: {
      type: {
        handler(newType, prevType) {
          if (prevType === 'month') {
            this.$nextTick(() => {
              this.$refs.calendar.scrollToTime(this.getCurrentTime() > 180 ? this.getCurrentTime() - 180 : 0);
            });
          }
        },
      },
    },

    methods: {
      getEventColor (event) {
        return event.color
      },

      viewDay ({ date }) {
        this.value = date;
        this.type = 'day';
      },

      showEvent ({ nativeEvent, event }) {
        // Empêcher de cliquer sur les jours fériés
        if (event.creneauId) {
          const open = () => {
            this.selectedEvent = event;
            this.selectedElement = nativeEvent.target;
            requestAnimationFrame(() => requestAnimationFrame(() => { this.selectedOpen = true }));
          }

          if (this.selectedOpen) {
            this.selectedOpen = false;
            requestAnimationFrame(() => requestAnimationFrame(() => open()));
          } else {
            open();
          }

          nativeEvent.stopPropagation();
        }
      },

      copyToClipboard() {
        navigator.clipboard.writeText(this.urlPlanning);
        this.tooltipIcon = 'mdi-clipboard-check-multiple';

        const input = document.getElementById('url-planning');
        input.focus();
        input.select();
      },

      updateEvents() {
        // Charge les créneaux au chargement de la page et à tout changement d'état
        if (this.ready) {
          this.events = [...this.joursFeries];

          this.axios.get(`/creneaux/annee/${this.$refs.calendar.lastStart.year}/mois/${this.$refs.calendar.lastStart.month}`)
            .then((res) => {
              this.creneaux = [...res.data];
              for (const creneau of this.creneaux) {
                if (!creneau.formateur) {
                  creneau.formateur = { id: -1 };
                }
                if (this.speakersSelected.includes(creneau.formateur.id) && this.etatsSelected.includes(creneau.formationAssociee.etat)) {
                  this.events.push({
                    name: `${creneau.formationAssociee.intitule} (${creneau.intitule})`,
                    start: new Date(`${creneau.date}T${creneau.heureDebut}`),
                    end: new Date(`${creneau.date}T${creneau.heureFin}`),
                    color: creneau.formationAssociee.couleur ?? '#9f8fdb',
                    timed: true,
                    formateur: creneau.formateur,
                    adresse: creneau.formationAssociee.adresse,
                    distanciel: creneau.formationAssociee.distanciel,
                    formationId: creneau.formationAssociee.id,
                    creneauId: creneau.id,
                  });
                }
              }
            });
        }
      },

      updateURL() {
        let etatsStr = '';
        let speakersStr = '';

        if (this.etatsSelected.length !== this.etats.length) {
          etatsStr = this.etatsSelected.join(',');
        }
        if (this.speakersSelected.length !== this.speakers.length) {
          speakersStr = this.speakersSelected.join(',');
        }

        let apiUrl = '/planning/getURL';

        if (etatsStr !== '' && speakersStr === '') {
          apiUrl += '?etats=' + etatsStr;
        }
        if (etatsStr === '' && speakersStr !== '') {
          apiUrl += '?formateurs=' + speakersStr;
        }
        if (etatsStr !== '' && speakersStr !== '') {
          apiUrl += '?etats=' + etatsStr + '&formateurs=' + speakersStr;
        }

        this.axios.get(apiUrl)
          .then((res) => {
            this.urlPlanning = res.data;
          });
      },

      toggle () {
        this.$nextTick(() => {
          if (this.allSpeakers) {
            this.speakersSelected = [];
          } else {
            this.speakersSelected = this.speakers.map(s => s.id).slice();
          }
          this.updateURL();
          this.updateEvents();
        })
      },

      nowY (timeToY) {
        return this.cal ? timeToY(this.cal.times.now) + 'px' : '-10px'
      },

      getCurrentTime () {
        return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0;
      },

      scrollToTime () {
        const time = this.getCurrentTime();
        const first = Math.max(0, time - (time % 30) - 30);
        this.cal.scrollToTime(first);
      },

      updateTime () {
        setInterval(() => this.cal.updateTimes(), 60 * 1000);
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },
  }
</script>

<style scoped>
  #calendar >>> .v-calendar-weekly__day-label .v-btn,
  #calendar >>> .v-calendar-daily_head-day-label .v-btn {
    margin-right: 0 !important;
  }

  #calendar >>> .v-calendar-weekly__day-label .v-btn.transparent,
  #calendar >>> .v-calendar-daily_head-day-label .v-btn.transparent {
    color: #858585 !important;
  }

  .v-calendar .v-event {
    background-color: #4963ca;
    border-color: #4963ca;
  }

  .card-creneau {
    border-radius: 0;
    margin: 0;
  }

  #calendar >>> #url-planning {
    cursor: pointer;
  }

  #calendar >>> .v-current-time {
    height: 2px;
    background-color: #ea4335;
    position: absolute;
    left: -1px;
    right: 0;
    pointer-events: none;
  }

  #calendar >>> .v-current-time.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
</style>
